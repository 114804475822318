import React from "react";
const About = React.lazy(() => import("./components/About"));
const Contact = React.lazy(() => import("./components/Contact"));
const Navbar = React.lazy(() => import("./components/Navbar"));
const Projects = React.lazy(() => import("./components/Projects"));
const Skills = React.lazy(() => import("./components/Skills"));
const Hobbies = React.lazy(() => import("./components/Hobbies"));
const Footer = React.lazy(() => import("./components/Footer"));

export default function App() {
  return (
    <main className="text-gray-50 bg-gray-900 body-font">
      <React.Suspense fallback={<p>Loading page...</p>}>
        <Navbar />
        <About />
        <Projects />
        <Skills />
        <Hobbies />
        <Contact />
        <Footer />
      </React.Suspense>
    </main>
  );
}
